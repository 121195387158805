<template>

  <div>

    <vue-element-loading
      style="min-height: 500px;"
      :active="carregamentoApi"
      text="Carregando Pendências"
    >
      <img
        src="@/assets/images/logo/logo-loading.gif"
        width="40px"
      >
    </vue-element-loading>

    <!-- Table Container Card -->
    <section
      id="knowledge-base-content"
      class="pt-2 pb-5"
    >
      <b-card
      v-for="aprovacao in aprovacoes"
      :key="aprovacao.id"
      class="card-transaction"
      no-body
    >

      <b-card-body>
        <div
          class="transaction-item"
        >
          <b-media no-body>
            <b-media-body>
              <h6 class="transaction-title">
                <span>{{ aprovacao.nomeComposicao }}</span>
              </h6>
              <b-badge
                pill
                :variant="`light-${statusAprovacaoVariant(aprovacao.statusAprovacaoHit)}`"
                class="text-capitalize badgeStatus"
              >
                {{ statusAprovacaoLabel(aprovacao.statusAprovacaoHit) }}
              </b-badge>
              <div class="audio-player" />
            </b-media-body>
          </b-media>

          <feather-icon
            size="20"
            icon="SearchIcon"
            style="cursor: pointer"
            @click="$router.push({ name: 'hit-editar', params: { id: aprovacao.id } })"
          />
        </div>
      </b-card-body>

    </b-card>

    <infinite-loading
      ref="infiniteLoading"
      :infinite-scroll-disabled="carregamentoApi"
      @infinite="hitCompositorPendencias"
      no-results="noResults"
    >
      <span slot="spinner">
        <img src="@/assets/images/logo/logo-loading.gif" width="40px">
        <p>Carregando mais pendências...</p>
      </span>
      <span slot="no-more"></span>
    </infinite-loading>
  </section>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BMediaBody, BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import VueElementLoading from 'vue-element-loading'
import useJwt from '@/auth/jwt/useJwt'
import UsersListFilters from './UsersListFilters.vue'
import aprovacoes from '@/router/routes/aprovacoes'
import InfiniteLoading from 'vue-infinite-loading'

// CARREGANDO DE PÁGINA PARA API

export default {
  components: {
    UsersListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BMediaBody,
    BCardBody,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,

    vSelect,
    VueElementLoading,
    InfiniteLoading
  },
  data() {
    return {

      aprovacoes: [],
      page: 0,
      size: 10,
      carregamentoApi: false,
    }
  },
  methods: {

    // STATUS

    statusAprovacaoVariant(status) {
      if (status === 'EM_ANALISE') return 'warning'
      if (status === 'APROVADO') return 'success'
      if (status === 'REPROVADO_GERAL') return 'danger'
      if (status === 'REPROVADO_INFORMACOES') return 'danger'
      if (status === 'REPROVADO_GUIA') return 'danger'
      if (status === 'PENDENTE_PAGAMENTO') return 'danger'
      if (status === 'AGUARDANDO_GUIA') return 'warning'
      return 'warning'
    },

    statusAprovacaoLabel(status) {
      if (status === 'EM_ANALISE') return 'Em análise'
      if (status === 'APROVADO') return 'Aprovado'
      if (status === 'REPROVADO_GERAL') return 'Reprovado'
      if (status === 'REPROVADO_INFORMACOES') return 'Informações Reprovadas'
      if (status === 'REPROVADO_GUIA') return 'Guia Reprovada'
      if (status === 'PENDENTE_PAGAMENTO') return 'Pendente Pagamento'
      if (status === 'AGUARDANDO_GUIA') return 'Aguardando Guia'
      return 'Em análise'
    },

    // STATUS

    // CARREGAR PENDÊNCIAS

    async hitCompositorPendencias(infiniteLoading) {
      this.carregamentoApi = true

      useJwt.hitCompositorPendencias({
        page: this.page,
        size: this.size,
        sort: 'ASC',
      })
        .then(response => {
          if(response.data.content.length) {
            this.page += 1
            this.aprovacoes.push(...response.data.content)
            infiniteLoading.loaded()
          } else {
            infiniteLoading.complete()
          }
        })
        .catch(error => {
          infiniteLoading.complete()
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },

  },

}

</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.transaction-title {
  text-transform: lowercase;
}

.transaction-title::first-letter {
  text-transform: capitalize;
}

.card-transaction {
  margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
  .transaction-title {
      width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
  }
}

</style>
